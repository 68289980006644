import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import MainFeature3 from "components/features/VerticalWithAlternateImageAndText.js"
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import FAQ from "components/faqs/SingleCol.js";
import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/slack_sc.png";
import macHeroScreenshotImageSrc from "images/Frame1.png";
import prototypeIllustrationImageSrc from "images/2.svg";
import prototypeIllustrationImageSrc2 from "images/Frame2.png";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <MainFeature
        subheading={<Subheading></Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading></Subheading>}
        heading={
          <>
            <HighlightedText>Educating.</HighlightedText> Not Shaming
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature3
        subheading={<Subheading>Diversity Calendar</Subheading>}
        heading={
          <>
            Workplace <HighlightedText>D&I Calendar</HighlightedText>
          </>
        }
        textOnLeft={true}
        imageSrc={prototypeIllustrationImageSrc2}
        showDecoratorBlob={false}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            Inclusive teams are <HighlightedText>high performing</HighlightedText> teams
          </>
        }
        textOnLeft={false}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Try it for Free for <HighlightedText>14 days.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Diversity Bot",
            price: "$29.99",
            duration: "Monthly",
            features: ["Checks 500+ non-inclusive terms and phrases", "Unlimited Slack channels", "Unlimited messages"],
            url: "https://slack.com/apps/A03RERP0BQQ-diversitybot",
            buttonName: "Try it for free"
          },
          {
            name: "Diversity Bot & DEI calendar",
            price: "$39.99",
            duration: "Monthly",
            features: ["Diversity and inclusion calendar", "Checks 500+ non-inclusive terms and phrases", "Unlimited Slack channels", "Unlimited messages"],
            url: "https://slack.com/apps/A03RERP0BQQ-diversitybot",
            buttonName: "Try it for free",
            featured: true
          },
          {
            name: "ENTERPRISE",
            price: "Contact us",
            duration: "For special pricing",
            features: ["Checks 500+ non-inclusive terms and phrases", "Unlimited Slack channels", "Unlimited messages",  "Diversity and inclusion calendar", "Priority support"],
            url: "mailto:support@diversitybot.app",
            buttonName: "Contact us"
          }
        ]}
      />
      <SimpleSubscribeNewsletter/>
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our users love  <HighlightedText>Diversity Bot.</HighlightedText>
          </>
        }
        testimonials={[
          {
            heading: "I never miss wishing my muslim collegue a Happy Ramadan anymore!",
            quote:
            "One of our main goals has always been to build and maintain an inclusive team culture. This is a difficult task when you have employees on almost every continent in the world. Diversity Bot helped bring us closer together.",
            customerName: "Charlotte Stale",
            customerTitle: "VP People, Delos."
          },
          {
            heading: "The Diversity calendar has been a great addition to our team culture!",
            quote:
              "Our team loves Diversity Bot. It is a great tool for educating our team on inclusive language.",
            customerName: "Adam Cupe",
            customerTitle: "Founder, Stacks"
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
