import React from "react";
import tw from "twin.macro";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg"
import {Container as ContainerBase } from "components/misc/Layouts.js"
import {SectionHeading} from "components/misc/Headings.js";
import {PrimaryButton} from "components/misc/Buttons.js";


const Container = tw(ContainerBase)`bg-secondary-800 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`
const url = "//diversitybot.us12.list-manage.com/subscribe/post?u=864afe856689915f3e8522114&amp;id=f7def165a4&amp;f_id=00fe43e0f0n";
const SimpleForm = () => <MailchimpSubscribe url={url}/>
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`
const Button = tw(PrimaryButton)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-purple-900 text-white hocus:bg-purple-900 hocus:text-gray-300 border border-indigo-800 hocus:border-indigo-800`

const Formed = () => (
  <Form method="get" action="#">
  <Input type="email" placeholder="Your Email Address" />
  <Button type="submit" >Subscribe</Button>
  </Form>
)

const Myform = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="flex flex-col">
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <Input
        ref={(node) => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <Button
        onClick={submit}
      >
        Submit
      </Button>
    </div>
  );
};
const CustomForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <Myform 
        onValidated={(formData) => subscribe(formData)}
        onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
      </div>
    )}
  />
)

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-purple-900`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`



export default () => {
  return (
    <Container>
      <Content>
        <Row>
          
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>Inclusive Language</Heading>
              <Heading>cheatsheet</Heading>
              <Description>Subscribe now to get our latest blog posts and our very own inclusive language cheatsheet</Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            <CustomForm></CustomForm>
          </FormColumn>
        </Row>
      </Content>
    </Container>
    
  );
};
