import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: September 21, 2022</p>
            <h3>Information we collect</h3>
            <p>When an Administrator adds DiversityBot to your chat platform (e.g. Slack, Microsoft Teams, Discord), we only collect information necessary for our Services to function.
Here is what we collect and why:
1. The names and URLs of chat platforms you use with DiversityBot.
- Used to display names in the product, identify your platform for support, and associate information about your platform to itself.
2. The name of the Community associated with your DiversityBot installation
3. The number of people in your Community
- We use this to charge you appropriately for our Product
4. The full name and email address of Administrators
- If you are an Administrator, we may use your email to contact you regarding your product usage. This includes things like billing, troubleshooting and onboarding. We strive to send as little email possible.
For Slack channels that you add DiversityBot to, we capture messages sent by your Community members within these channels (and only within these channels). In order to operate, maintain, and provide to you the features and functionality of our Service we only collect necessary information related to messages, including the message contents, timestamp, giver, receiver, and where the message was located (i.e. channel name, chat platform, etc.). The contents of these messages are not stored in a storage (i.e. in a database). Messages are processed by the application and immediately discarded.</p>
<h3>Who has access to your data</h3>
<p>
Your information will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the Services requested. We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. An example is our payment processor. They use your email address and name to send you receipts for your payments. Some of the trusted third parties that we currently engage with include:
1. Stripe. Billing and payments
2. Heroku. Hosting provider for app and data storage
3. Amazon Web Services. Hosting provider for app and data storage
4. Google Analytics. Service analytics to track and analyze Service usage
We may add parties to this list as our product grows so that we can serve you better. We may also release your information when we believe releasing it is appropriate to comply with the law, enforce our site policies, or protect our or others’ rights, property, or safety.</p>
<h3>Legal bases for processing</h3>
<p>We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only where we have legal bases for doing so.

These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:

it's necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);
it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote our services, and to protect our legal rights and interests;
you give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or
we need to process your data to comply with a legal obligation.
Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).

We don't keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person.</p>
<h3>Your rights and controlling your personal information</h3>
<p>Choice and consent: By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this privacy policy. If you are under 16 years of age, you must have, and warrant to the extent permitted by law to us, that you have your parent or legal guardian's permission to access and use the website and they (your parents or guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however, if you do not, it may affect your use of this website or the products and/or services offered on or through it.

Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person's consent to provide the personal information to us.

Restrict: You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. If you ask us to restrict or limit how we process your personal information, we will let you know how the restriction affects your use of our website or products and services.

Access and data portability: You may request details of the personal information that we hold about you. You may request a copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable machine format. You may request that we erase the personal information we hold about you at any time. You may also request that we transfer this personal information to another third party.

Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.

Notification of data breaches: We will comply laws applicable to us in respect of any data breach.

Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.

Unsubscribe: To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</p>
         <h3>Limits of our policy</h3>
         <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
         <h3>Changes to this policy</h3>
         <p>At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.

If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.</p>
          <h3>Contact Us</h3>
          <p>If you have any questions, please contact us at <a href = "mailto: support@diversitybot.com">support@diversitybot.app</a></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
