import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

// import CheatsheetPage from "inclsusivecheatsheet.pdf";
import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import ContactUsPage from "pages/ContactUs.js";
import BlogIndexPage from "pages/BlogIndex.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import PageNotFound from "pages/PageNotFound.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <Router>
      <Switch>
        <Route path="/contact">
          <ContactUsPage />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicyPage />
        </Route>
        <Route path="/blog">
        <BlogIndexPage />
        </Route>
        <Route path="/cheatsheet" component={() => {
           window.location.href = 'https://drive.google.com/file/d/1plvrU6z9Knd6fwNsuUetoYPZRP-dRZ5J/view';
           return null;
          }}/>
        <Route path="/">
          <SaaSProductLandingPage />
        </Route>
        // <Route path='*' exact={true} component={PageNotFound} />
      </Switch>
    </Router>
  );
}
